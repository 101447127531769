%site-font {
    font-family: 'Work Sans', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.primary-text-colour {
    color: $black;
}

.secondary-text-colour {
    color: $black;
}

.component__text h2 {
    font-weight: 700;
    letter-spacing: .025em;
    line-height: 1.4;
}

h1 {
    @extend %site-font;
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 20px;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.02em;
}

h2 {
    @extend %site-font;
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 20px;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.02em;
}

body {
    @extend %site-font;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
}

.p-small {
    @extend %site-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

.main-text-colour {
    color: $body-font-color;
}

a {
    color: $primary-color;
    &:hover {
        color: $indie-pubs-primary;
    }
}

p {
    margin-bottom: 1em;
}

.primary-button {
    @extend %site-font;
    @include siteButton($primary-color,
    $white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    &:hover {
        background-color: rgba($primary-color, 0.5);
        color: $white;
    }
}