@import "variables";
@import "typography";

body {
    letter-spacing: .05em;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

.container {
    max-width: $max-width;
}