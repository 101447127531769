.social-medias {
    text-align: center;
    @include breakpoint(sm) {
        text-align: right;
    }
}
.icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    padding: 5px 3px;
    font-size: 25px;
    color: $socialLogos;
    transition: background-color 0.3s ease-in-out;
    margin-left: 10px;
    &:hover {
        background-color: $socialHover;
    }
    &-facebook {
        background-color: $facebook;
    }
    &-twitter {
        background-color: $twitter;
    }
    &-instagram {
        background: $instagram;
    }
    &-gplus {
        background-color: $gplus;
    }
    &-linkedin {
        background-color: $linkedin;
    }
    &-pinterest {
        background-color: $pintrest;
    }
    &-snapchat-ghost {
        background-color: $snapchat;
    }
    &-vimeo {
        background-color: $vimeo;
    }
    &-youtube-play {
        background-color: $youtube;
    }
  }