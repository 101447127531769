.header__nav-draw {
    background-color: $white;
    &--navigation a {
    color: $black;
    padding: 15px;
    font-weight: 700;
    }
}

.header__nav-draw--navigation li {
    border-top: 1px solid $black;
    border-bottom: none;

    .login a {
        font-size: 13.6px;
        font-weight: 500;
    }

    a {
        &:hover {
            color: $black;
        }
    }
}

.header__nav-draw--header {
    border-bottom: none;
    .icon-cancel:before {
        color: $black;
    }
}