.publisher-hero {
    .hero-simple__featured-image {
        padding: 40px 10px;
        width: 100%;
        @include breakpoint(sm) {
            width: auto !important;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include breakpoint(md) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        a {
            display: block;
        }
        img {
            margin: 0 auto;
            max-width: 300px;
            height: 220px;
            object-fit: contain;
            @include breakpoint(sm) {
                margin: 0;
            }
        }
    }
}