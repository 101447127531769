.header, .sticky-header--container.is-fixed {
    border-bottom: 1px solid $header-border;
}

.sticky-header--container {
    &.is-fixed {
        .sticky-logo {
            display: block;
        }
        .desk-logo {
            display: none;
        }
        .header__icons {
            @include breakpoint(md) {
                margin-bottom: 15px;
            }
        }
        @media (min-width: 48rem) {
            .header__container {
                padding: 30px 40px 0 40px;
            }
            .main-nav {
                margin: 0 0 28px;
            }
        }
    }
}

.header {
    &__container {
        padding: 0 0 0 17px;
        @include breakpoint(md) {
            padding: 30px 40px;
        }
        max-width: 1500px;
    }
    &__icons {
        margin-bottom: 10px;
        @include breakpoint(md) {
            margin-bottom: 0;
        }
    }
    &__logo {
        .sticky-logo {
            display: none;
        }
    }
    &__icon {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 7.5px;
        font-size: 25px !important;
        @include breakpoint(md) {
            font-size: 20px !important;
        }

        &.account {
            display: none;
            @media (min-width: 768px) {
                display: block;
            }
        }

        &.hamburger {
            position: relative;
            top: -2px;
            @media (min-width: 768px) {
                display: none;
            }
        }
        @include breakpoint(md) {
            padding-left: 5px;
        }
        @include breakpoint(lg) {
            padding-left: 20px;
        }
        a {
            color: $header-icons;
            &:hover {
                color: $primary-color;
            }
        }
        .hamburger {
            width: 28px;
            height: 28px;
            &-box {
                height: 28px;
            }
        }

        .hamburger-inner::before,
        .hamburger-inner::after {
            width: 28px;
        }

        .hamburger-inner:before {
            top: -8px;
        }

        .hamburger-inner:after {
            bottom: -8px;
        }

        .hamburger-inner {
            width: 22px;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $header-icons;
            height: 3px;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            height: 155px;
            width: 150px;
            margin: 10px 0;
        }
        &__image {
            max-width: 140px;
        }
    }
}

.main-nav {
    .main-menu {
        padding-left: 20px;
        justify-content: flex-start;
        max-width: 1132px;
    }
    @include breakpoint(md) {
        margin: 0 0 3px 0;
    }
    a {
        font-size: 18px;
        margin: 0;
        padding: 7.5px 13px;

        @include breakpoint(lg) {
            font-size: 22px;
            margin: 0 18px;
            padding: 7.5px 15px;
        } 

        line-height: 1.4;
        font-style: normal;
        font-weight: 700;
        letter-spacing: .025em;
        text-transform: uppercase;
        color: $nav-links-colour;
        position: relative;
        -webkit-font-smoothing: antialiased;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 100%;
            margin: 0 15px;
            border-bottom: 2px solid;
            transition: right .5s;
        }
        &:hover {
            &:after {
                right: 0;
            }
        }
    }
}