/*
	Breakpoints
*/
@mixin breakpoint($point) {
    @if $point == sm {
      @media (min-width: rem(640px)) {
        @content;
      }
    }

    @if $point == md {
        @media (min-width: rem(768px)) {
          @content;
        }
    }

    @if $point == lg {
        @media (min-width: rem(1024px)) {
          @content;
        }
    }    
    
    @if $point == xl {
        @media (min-width: rem(1280px)) {
          @content;
        }
    }

    @if $point == 2xl {
        @media (min-width: rem(1536px)) {
          @content;
        }
    }
}