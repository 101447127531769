.publisher-intro {
    h1 {
        margin: 0; 
        font-weight: 700;
        letter-spacing: .025em;
        line-height: 1.4;
    }
    &__description {
        font-size: 16px;
        line-height: 22px;
    }
}