.newsletter-signup {
    h2 {
        font-size: 23px;
        letter-spacing: .025em;
        font-weight: bold;
        color: $black;
    }
    .form-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        input {
            width: 100%;
            @include breakpoint(sm) {
                width: 300px;
            }
        }
    }
    .form-text {
        .form-control {
            height: 45px;
            border: 1px solid $black;
            &::placeholder,  &:-ms-input-placeholder, &::-webkit-input-placeholder  {
                font-weight: 500;
                color: $black;
                font-size:16px;
            }
        }
    }
    .form-button {
        @extend %site-font;
        @include siteButton($black,
        $white);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        height: 45px;
        letter-spacing: .2em;
        @include breakpoint(sm) {
            width: auto;
        }
        &:hover {
            background-color: rgba($primary-color, 0.5);
            color: $white;
        }
    }
    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 0;
    }
}

.background-newsletter {
    background-color: #f7f6f1;
    color: $primary-color;
}