$black: #000;
$white: white;

$max-width: 1500px;

$animation-time: 0.3s;

$primary-color: #3C3C3C;
$secondary-color: #1c1d1d;

$indie-pubs-primary: #177d58;
$indie-pubs-secondary: #C55E4A;

$header-icons: $black;
$header-border: $black;
$body-font-color: $primary-color;
$nav-links-colour: $black;

$socialLogos: $white;
$socialHover: $secondary-color;
$instagram: #C13584;
$linkedin: #0077B5;
$soundcloud:#ff5100;
$pintrest:#cf2121;
$snapchat:#FFFC00;
$gplus:#d34836;
$twitter: #00aced;
$vimeo: #1ab7ea;
$facebook: #4a6ea9;

$youtube: #35465c;