.footer {
    border-top: 1px solid $black;
    .footer__copyright {
        display: none;
    }
    .footer__credit {
        font-size: 11.2px;
    }
    .footer-bottom {
        .footer__copyright {
            display: block;
            font-size: 11.2px;
        }
    }
    a {
        color: $black;
        &:hover {
            color: $indie-pubs-primary;
        }
    }
}

.footer__nav {
    li {
        position: relative;
        &:not(:last-of-type) {
            padding-right: 8px;
        }
        &:not(:first-of-type) {
            padding-left: 8px;
        }
        &:not(:last-of-type)::after {
            content: "";
            background: $indie-pubs-primary;
            position: absolute;
            bottom: 25%;
            right: -8px;
            height: 50%;
            width: 1px;
            margin-right: 8px;
        }
        a {
            color: $black;
            text-transform: uppercase;
            font-size: 12px;
            display: inline-block;
            padding: 4px 0;
            font-weight: 500;
            line-height: 19.95px;
            letter-spacing: 0.8px
        }
    }
}

.footer-bottom {
    color: $black;
    @extend %site-font;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0em;
}